@font-face {
  font-family: 'yen-sao-hao-phong';
  src: url('../fonts/font-icon/yen-sao-hao-phong.eot?uf3yg1');
  src: url('../fonts/font-icon/yen-sao-hao-phong.eot?uf3yg1#iefix') format('embedded-opentype'),
    url('../fonts/font-icon/yen-sao-hao-phong.ttf?uf3yg1') format('truetype'),
    url('../fonts/font-icon/yen-sao-hao-phong.woff?uf3yg1') format('woff'),
    url('../fonts/font-icon/yen-sao-hao-phong.svg?uf3yg1#yen-sao-hao-phong') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'yen-sao-hao-phong' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e964";
}

.icon-add-circle-bold:before {
  content: "\e901";
}

.icon-add-square-bold:before {
  content: "\e902";
}

.icon-arrow-circle-left-bold:before {
  content: "\e917";
}

.icon-arrow-circle-right-bold:before {
  content: "\e918";
}

.icon-arrow-circle-up-bold:before {
  content: "\e919";
}

.icon-arrow-down-bold:before {
  content: "\e91a";
}

.icon-arrow-down-bold1:before {
  content: "\e91b";
}

.icon-arrow-left-bold:before {
  content: "\e91c";
}

.icon-arrow-left-21:before {
  content: "\e91d";
}

.icon-arrow-right-31:before {
  content: "\e91e";
}

.icon-arrow-right1:before {
  content: "\e91f";
}

.icon-arrow-square-down1:before {
  content: "\e920";
}

.icon-arrow-square-left:before {
  content: "\e921";
}

.icon-arrow-square-right-bold:before {
  content: "\e922";
}

.icon-arrow-square-up-bold:before {
  content: "\e923";
}

.icon-arrow-up-bold:before {
  content: "\e924";
}

.icon-arrow-up-bold1:before {
  content: "\e925";
}

.icon-bag-bold:before {
  content: "\e926";
}

.icon-box-tick-bold:before {
  content: "\e927";
}

.icon-box-bold:before {
  content: "\e928";
}

.icon-call-calling-bold:before {
  content: "\e929";
}

.icon-call-bold:before {
  content: "\e92a";
}

.icon-card-pos-bold:before {
  content: "\e92b";
}

.icon-card-tick-bold:before {
  content: "\e92c";
}

.icon-card-bold:before {
  content: "\e92d";
}

.icon-discount-circle-bold:before {
  content: "\e92e";
}

.icon-discount-shape-bold:before {
  content: "\e92f";
}

.icon-frame-bold:before {
  content: "\e930";
}

.icon-heart-bold:before {
  content: "\e931";
}

.icon-home-bold:before {
  content: "\e933";
}

.icon-location-bold:before {
  content: "\e965";
}

.icon-ranking-bold:before {
  content: "\e934";
}

.icon-setting-bold:before {
  content: "\e935";
}

.icon-shop-bold:before {
  content: "\e936";
}

.icon-shopping-cart-bold:before {
  content: "\e937";
}

.icon-tag-bold:before {
  content: "\e938";
}

.icon-tag-cross-bold:before {
  content: "\e939";
}

.icon-tick-circle-bold:before {
  content: "\e93a";
}

.icon-tick-square-bold:before {
  content: "\e93b";
}

.icon-ticket-discount-bold:before {
  content: "\e976";
}

.icon-truck-fast-bold:before {
  content: "\e904";
}

.icon-truck-tick-bold:before {
  content: "\e905";
}

.icon-truck-time-bold:before {
  content: "\e93c";
}

.icon-truck-bold:before {
  content: "\e93d";
}

.icon-user-bold:before {
  content: "\e93e";
}

.icon-verify-bold:before {
  content: "\e93f";
}

.icon-add-circle-outline:before {
  content: "\e940";
}

.icon-add-square-outline:before {
  content: "\e941";
}

.icon-add-outline:before {
  content: "\e942";
}

.icon-arrange-circle-2-outline:before {
  content: "\e943";
}

.icon-arrange-circle-outline:before {
  content: "\e944";
}

.icon-arrange-square-2-outline:before {
  content: "\e945";
}

.icon-arrange-square-outline:before {
  content: "\e946";
}

.icon-arrow-2-outline:before {
  content: "\e947";
}

.icon-arrow-3-outline:before {
  content: "\e948";
}

.icon-arrow-circle-down-outline:before {
  content: "\e949";
}

.icon-arrow-circle-left-outline:before {
  content: "\e94a";
}

.icon-arrow-circle-righ-outlinet:before {
  content: "\e94b";
}

.icon-arrow-circle-up-outline:before {
  content: "\e94c";
}

.icon-arrow-down-1-outline:before {
  content: "\e94d";
}

.icon-arrow-down-2-outline:before {
  content: "\e94e";
}

.icon-arrow-down-outline:before {
  content: "\e94f";
}

.icon-arrow-left-2-outline:before {
  content: "\e950";
}

.icon-arrow-left-3-outline:before {
  content: "\e951";
}

.icon-arrow-left-outline:before {
  content: "\e952";
}

.icon-arrow-right-1-outline:before {
  content: "\e953";
}

.icon-arrow-right-2-outline:before {
  content: "\e954";
}

.icon-arrow-right-3-outline:before {
  content: "\e955";
}

.icon-arrow-right-outline:before {
  content: "\e956";
}

.icon-arrow-square-left-outline:before {
  content: "\e958";
}

.icon-arrow-square-up-outline:before {
  content: "\e959";
}

.icon-arrow-up-1-outline:before {
  content: "\e95a";
}

.icon-arrow-up-2-outline:before {
  content: "\e95b";
}

.icon-arrow-up-3-outline:before {
  content: "\e95c";
}

.icon-bag-outline:before {
  content: "\e95d";
}

.icon-box-search-outline:before {
  content: "\e95e";
}

.icon-box-tick-outline:before {
  content: "\e95f";
}

.icon-call-calling-outline:before {
  content: "\e960";
}

.icon-call-outline:before {
  content: "\e961";
}

.icon-card-pos-outline:before {
  content: "\e962";
}

.icon-cards-outline:before {
  content: "\e963";
}

.icon-chart-1-outline:before {
  content: "\e966";
}

.icon-chart-square-outline:before {
  content: "\e967";
}

.icon-clock-outline:before {
  content: "\e968";
}

.icon-close-circle-outline:before {
  content: "\e969";
}

.icon-close-square-outline:before {
  content: "\e96a";
}

.icon-coin-outline:before {
  content: "\e96b";
}

.icon-convert-3d-cube-outline:before {
  content: "\e96c";
}

.icon-convert-card-outline:before {
  content: "\e96d";
}

.icon-danger-outline:before {
  content: "\e96e";
}

.icon-devices-outline:before {
  content: "\e96f";
}

.icon-discount-circle-outline:before {
  content: "\e970";
}

.icon-discount-shape-outline:before {
  content: "\e971";
}

.icon-discover-1-outline:before {
  content: "\e972";
}

.icon-dislike-outline:before {
  content: "\e973";
}

.icon-dollar-circle:before {
  content: "\e900";
}

.icon-dollar-square:before {
  content: "\e903";
}

.icon-edit-outline:before {
  content: "\e974";
}

.icon-element-3-outline:before {
  content: "\e975";
}

.icon-emoji-happy-outline:before {
  content: "\e977";
}

.icon-emoji-normal-outline:before {
  content: "\e978";
}

.icon-emoji-sad-outline:before {
  content: "\e979";
}

.icon-export-2-outline:before {
  content: "\e97a";
}

.icon-eye-slash-outline:before {
  content: "\e97c";
}

.icon-eye-outline:before {
  content: "\e97d";
}

.icon-filter-add-outline:before {
  content: "\e97e";
}

.icon-filter-remove-outline:before {
  content: "\e97f";
}

.icon-filter-tick-outline:before {
  content: "\e980";
}

.icon-filter-outline:before {
  content: "\e981";
}

.icon-firstline-outline:before {
  content: "\e957";
}

.icon-frame-1-outline:before {
  content: "\e982";
}

.icon-gallery-favorite-outline:before {
  content: "\e983";
}

.icon-gallery-outline-outline:before {
  content: "\e906";
}

.icon-global-search-outline:before {
  content: "\e984";
}

.icon-global-outline:before {
  content: "\e985";
}

.icon-heart-outline:before {
  content: "\e986";
}

.icon-home-outline:before {
  content: "\e907";
}

.icon-home-trend-down-outline:before {
  content: "\e987";
}

.icon-home-trend-up-outline:before {
  content: "\e988";
}

.icon-house-outline-outline:before {
  content: "\e909";
}

.icon-info-circle-outline:before {
  content: "\e90a";
}

.icon-like-outline:before {
  content: "\e90b";
}

.icon-like-tag-outline:before {
  content: "\e989";
}

.icon-like:before {
  content: "\e98a";
}

.icon-location-tick-outline:before {
  content: "\e98b";
}

.icon-location-outline:before {
  content: "\e98c";
}

.icon-logout-outline:before {
  content: "\e98d";
}

.icon-map-1-outline:before {
  content: "\e98e";
}

.icon-map-outline:before {
  content: "\e98f";
}

.icon-maximize-4:before {
  content: "\e908";
}

.icon-medal-star-outline:before {
  content: "\e990";
}

.icon-menu-outline:before {
  content: "\e912";
}

.icon-message-question-outline:before {
  content: "\e913";
}

.icon-milk-outline:before {
  content: "\e991";
}

.icon-money-send-outline:before {
  content: "\e992";
}

.icon-moon-outline:before {
  content: "\e993";
}

.icon-more-outline:before {
  content: "\e914";
}

.icon-notification-outline:before {
  content: "\e90c";
}

.icon-paperclip-2-outline:before {
  content: "\e994";
}

.icon-personalcard-outline:before {
  content: "\e915";
}

.icon-play-circle-outline:before {
  content: "\e995";
}

.icon-profile-circle-outline:before {
  content: "\e90d";
}

.icon-profile-tick-outline:before {
  content: "\e996";
}

.icon-ranking-1-outline:before {
  content: "\e997";
}

.icon-redo-outline:before {
  content: "\e916";
}

.icon-repeat-outline:before {
  content: "\e99a";
}

.icon-rotate-left-outline:before {
  content: "\e99b";
}

.icon-setting-outline-outline:before {
  content: "\e90e";
}

.icon-shield-tick-outline:before {
  content: "\e99d";
}

.icon-shop-outline-outline:before {
  content: "\e90f";
}

.icon-shopping-cart-outline:before {
  content: "\e99e";
}

.icon-star-outline:before {
  content: "\e99f";
}

.icon-sun-outline:before {
  content: "\e9a0";
}

.icon-sun-fog-outline:before {
  content: "\e9a1";
}

.icon-tag-2-outline:before {
  content: "\e9a2";
}

.icon-tag-outline:before {
  content: "\e9a3";
}

.icon-tick-circle:before {
  content: "\e9a4";
}

.icon-tick-square:before {
  content: "\e9a5";
}

.icon-ticket-discount-outline:before {
  content: "\e9a6";
}

.icon-translate-outline:before {
  content: "\e9a7";
}

.icon-truck-fast-outline:before {
  content: "\e910";
}

.icon-truck-remove-outline:before {
  content: "\e9a8";
}

.icon-truck-tick-outline:before {
  content: "\e9a9";
}

.icon-truck-time-outline:before {
  content: "\e9aa";
}

.icon-truck-outline:before {
  content: "\e9ab";
}

.icon-undo-outline:before {
  content: "\e932";
}

.icon-unlimited-outline:before {
  content: "\e9ac";
}

.icon-user-tick-outline:before {
  content: "\e9b0";
}

.icon-user-outline:before {
  content: "\e911";
}