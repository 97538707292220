@font-face {
  font-family: 'Inter';
  src: url("../fonts/font-text/Inter/Inter-Light-slnt=0.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/font-text/Inter/Inter-Regular-slnt=0.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/font-text/Inter/Inter-Medium-slnt=0.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/font-text/Inter/Inter-SemiBold-slnt=0.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/font-text/Inter/Inter-Bold-slnt=0.ttf") format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: "Effra";
  src: url("../fonts/font-text/Effra/Effra_Std_Rgn.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Effra";
  src: url("../fonts/font-text/Effra/Effra_Std_Bdn.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "iCiel Nabila";
  src: local("iCiel Nabila"), url("../fonts/font-text/Nobila/Nabila.ttf") format("opentype");
}
